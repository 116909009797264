import React, { useContext, useState, useEffect } from "react";
import { NavLink } from 'react-router-dom'
import MenuContext from "./MenuContext";

// @import Images

import Logo_FLYNAERO from '../images/logos/Flynaero-solo.svg';
import ico_linkedIn from "../images/icones/icons8-linkedin.svg";
import ico_YouTube from "../images/icones/youtube.png"
import ico_PDF from "../images/icones/pdf.png"

//Nav Buttons
import BtnNavEtudes from "./_btn_NavEtudes.js";
import BtnEasyFluid from "./_btn_EasyFluid.js"

export default function Navigation() {

    const {showBurger} = useContext(MenuContext);
    const {handleShowBurger} = useContext(MenuContext);
    const {EtudesScreen} = useContext(MenuContext);
    const {UnsetEtudesScreen} = useContext(MenuContext);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (width <= 1280) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    },[width]);

    if (EtudesScreen) {
        return (
            <nav className={`navBar nav_etudes ${showBurger ? "show-nav" : "hide-nav"}`}>
                <NavLink exact to="/" activeClassName="nav-active" onClick={UnsetEtudesScreen}><img className="navBar__logo" src={Logo_FLYNAERO} alt="Logo FLYNAERO" /></NavLink> 
                <ul className="navBar__links text-links">
                    <li className="navBar__link"><NavLink style={{ textDecoration: 'none' }} exact to="/BureauEtudes" activeClassName="active" onClick={handleShowBurger}>Bureau d'études</NavLink></li>
                    <li className="navBar__link"><NavLink style={{ textDecoration: 'none' }} exact to="/BureauEtudes/Fondamentaux" activeClassName="active" onClick={handleShowBurger}>
                        <BtnNavEtudes name="les fondamentaux" img={ico_PDF} alt="Icone PDF"/>
                    </NavLink></li>
                    <li className="navBar__link"><NavLink style={{ textDecoration: 'none' }} exact to="/BureauEtudes/Realisations" activeClassName="active" onClick={handleShowBurger}>
                        <BtnNavEtudes name="nos réalisations" img={ico_YouTube} alt="Icone Youtube"/>
                    </NavLink></li>
                    <li className="navBar__link">
                        <BtnEasyFluid />
                    </li>
                </ul>
                { isMobile && 
                    <button className="navBar__burger" onClick={handleShowBurger}>
                        <span className="burger-bar"></span>
                    </button>
                }         
            </nav>
        )
    } else {
        return (
            <nav className={`navBar ${showBurger ? "show-nav" : "hide-nav"}`}>
                <NavLink exact to="" activeClassName="nav-active"><img className="navBar__logo" src={Logo_FLYNAERO} alt="Logo FLYNAERO" /></NavLink> 
                <ul className="navBar__links text-links">
                    <li className="navBar__link"><NavLink style={{ textDecoration: 'none' }} exact to="/Entreprise"  activeClassName="active" onClick={handleShowBurger}>l'entreprise</NavLink></li>
                    <li className="navBar__link"><NavLink style={{ textDecoration: 'none' }} exact to="/Technologies" activeClassName="active" onClick={handleShowBurger}>la méthode DPSM</NavLink></li>
                    <li className="navBar__link"><NavLink style={{ textDecoration: 'none' }} exact to="/Objectifs" activeClassName="active" onClick={handleShowBurger}>objectifs</NavLink></li>
                    <li className="navBar__link"><NavLink style={{ textDecoration: 'none' }} exact to="/Actualites" activeClassName="active" onClick={handleShowBurger}>actualités</NavLink></li>
                    <li className="navBar__link"><NavLink style={{ textDecoration: 'none' }} exact to="/Nous" activeClassName="active" onClick={handleShowBurger}>l'équipe</NavLink></li>
                    <li className="navBar__link"><NavLink style={{ textDecoration: 'none' }} exact to="/Partenariat" activeClassName="active" onClick={handleShowBurger}>partenariat</NavLink></li>
                    <li className="navBar__link"><NavLink style={{ textDecoration: 'none' }} exact to="/Recrutement" activeClassName="active" onClick={handleShowBurger}>recrutement</NavLink></li>
                    <li className="navBar__link"><NavLink style={{ textDecoration: 'none' }} exact to="/Contact" activeClassName="active" onClick={handleShowBurger}>contact</NavLink></li>
                    <li className="navBar__link"><a href="https://www.linkedin.com/company/flynaero/"><img className="navBar__socials" src={ico_linkedIn} alt="Icone LinkedIn" /></a></li>
                </ul>
                { isMobile && 
                    <button className="navBar__burger" onClick={handleShowBurger}>
                        <span className="burger-bar"></span>
                    </button>
                }               
            </nav>
        )
    }
}