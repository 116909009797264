import React from "react";

import Histories from "../../bdd/histories.json"
import History from "./_EntrepriseHistory";
// Images

import Logo_FLYNAERO from "../../images/logos/Logo_FLYNAERO.jpg";

export default function Entreprise() {
    return(
            <main>
                <div className="entreprise">
                    <div className="entreprise-card">
                        <div className="entreprise-text">
                            <h2 className="titre-cartouche">L'entreprise</h2>
                            <p className="text-cartouche">FLYNAERO va s’appuyer sur une solution innovante pour répondre à certains enjeux, notamment en ce qui concerne la conception et la R&D. La CFD (Computational Fluid Dynamics), également appelée Mécanique des Fluides assistée par ordinateur, est un outil informatique de modélisation des mouvements de fluides (liquide, gaz), des propriétés de ces écoulements (vitesse, pression, température ...) et des interactions avec leur environnement (échange de chaleur, réactions chimiques, forces aérodynamiques, aéroacoustique).</p>
                        </div>
                    </div>
                    <div className="entreprise-picture" data-aos-anchor-placement="bottom-bottom"data-aos="flip-left" data-aos-easing="ease-in-out" data-aos-duration="1000">
                        <img src={Logo_FLYNAERO} alt="Logo FLYNAERO" />
                    </div>
                </div>
                <div className="history__card">
                    {Histories.map(history => {
                        return <History key={history.id}{...history} />
                    })}
                </div>
            </main>
    )
}