import React, { useEffect, useState } from "react";
import ActualityView from "./_ActualityView";

export default function Actuality(props) {
    const [showNews, setShowNews] = useState(false)
    const handleShowNews = () => {
        setShowNews(!showNews)
    }
    const paragraf = props.paragrafs[0].slice(0,100)

    useEffect(() => {
        const targets = document.querySelectorAll(".actuality__card")
        const setShowNewsOff = () => {
            setShowNews(false)
        }

        if(showNews === true){
            targets.forEach((target) => {
                target.style.display = "none";
                document.addEventListener('keydown', setShowNewsOff);
            })
        } else {
            targets.forEach((target) => {
                target.style.display = "flex";
                document.removeEventListener('keydown', setShowNewsOff);
            })
        } 
    },[showNews])
    
    return (
        <>
            { showNews ? <> <div className="x" onClick={handleShowNews}><span className="x-bar"></span></div> <ActualityView {...props} /> </> : "" }
            <div className="actuality__card" onClick={handleShowNews}>
                <div className="actuality__card__image">
                    <img className="" src={ props.image ? process.env.PUBLIC_URL + "/assets/images-actus/thumbnail_" + props.image : process.env.PUBLIC_URL + "/assets/images-actus/none.png"} alt="actu" />
                </div>
                <div className="actuality__card__text">
                    <h4 className="title-card">{props.title}</h4>
                    <div className="actuality__card__text__paragrafs">
                    <p className="text-card">{paragraf} ...</p>
                </div>
                <button onClick={handleShowNews}>Lire</button>
                </div>
            </div>
        </>
    )
}