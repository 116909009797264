import React from "react";

const Footer = () => {
    return(
        <div className="footer text-footer">
            <div className="footer-flyn">
                <p>SAS-flynaero</p>
            </div>
            <div className="footer-cookies">
                <p>Ce site utilise des cookies pour amélirer votre expérience de navigation. Aucune utilisation publicitaire n'est faite.</p>
            </div>
            <div className="footer-dev">
                <p>React by NCDevWeb</p>
            </div>
        </div>
    )
}

export default Footer