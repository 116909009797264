import React from "react";

import Picture_FluidSoftware from "../../images/easy-fluid/logiciel_fluides.jpg"
import Logo_Ansys from "../../images/easy-fluid/Logo_ANSYS.png"
import Logo_EducNational from "../../images/easy-fluid/Logo_EducNational.png"

export default function EasyFluid() {
    return (
        <div className="easy-fluid">
            <div className="easy-fluid__title">
                <h2 className="titre-cartouche">NOTRE LOGICIEL Easy-Fluid</h2>
            </div>
            <div className="easy-fluid__intro">
                <p className="text-cartouche">FLYNAERO est créateur d’un logiciel, innovant permettant de simuler des écoulements autour d’obstacles dans un temps record, de rechercher l’optimum et donc d’améliorer les performances des formes étudiées et d’en accélérer leur mise sur le marché. Ainsi, notre logiciel EASY-FLUID propose une solution innovante permettant d’explorer le champ des possibles et d’analyser toutes les hypothèses afin de comprendre, prévoir et décider.</p>
            </div>
            <div className="easy-fluid__presentation">
                <img src={Picture_FluidSoftware} alt="Capture de fenêtre du logiciel Easy-Fluid" />
                <p className="text-cartouche">Couplé à des outils de CAO tel que CATIA, Easy-Fluid est l’outil idéal pour booster la créativité des ingénieurs et permettre ainsi de sortir des schémas classiques de conception.</p>
            </div>
            <div className="easy-fluid__logos">
                <img src={Logo_Ansys} alt="Logo de l'ANSYS" />
                <img src={Logo_EducNational} alt="Logo du ministère de l'Éducation Nationnale" />
            </div>
            <div className="easy-fluid__footer">
                <div className="easy-fluid__footer__softwares">
                    <h4 className="titre-cartouche">Logiciels utilisés</h4>
                    <p className="text-cartouche">L’ensemble de nos simulations en mécanique des fluides sont contrôlées avec ANSYS Fluent , logiciel leader du marché et de loin le plus complet. Afin de renforcer la pertinence du résultat.</p>
                </div>
                <div className="easy-fluid__footer__studies">
                    <h4 className="titre-cartouche">L’ étude de Cas</h4>
                    <p className="text-cartouche">Toutes les études sont différentes par nature mais il est possible de définir une méthodologie générale et applicable.</p>
                    <ul>
                        <li className="text-cartouche">Discussion du projet avec nos équipes</li>
                        <li className="text-cartouche">Maillage du modèle</li>
                        <li className="text-cartouche">Choix des modèles numériques</li>
                        <li className="text-cartouche">Définition des conditions aux limites</li>
                        <li className="text-cartouche">Post-traitement</li>
                        <li className="text-cartouche">Reporting</li>
                    </ul>
                </div>
                <div className="easy-fluid__footer__accred">
                    <h4 className="titre-cartouche">Accréditation C.I.R.</h4>
                    <p className="text-cartouche">Pour tous les travaux de R&D que vous nous confiez, vous bénéficiez du Crédit Impôt Recherche de la même manière que si vous les réalisiez en interne.</p>
                </div>
            </div>
        </div>
    )
}