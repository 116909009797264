import React from "react";

// Images

import img_graph1 from "../../images/flynaero/graph1.jpg"
import img_graph2 from "../../images/flynaero/graph2.jpg"

export default function Objectifs() {
    return(
        <main name="bloc-objectifs" className="objectifs">
            <div className="objectifs-text">
                <h2 className="titre-cartouche">Les objectifs</h2>
                <p className="text-cartouche">FLYNAERO va s’appuyer sur une solution innovante pour répondre à certains enjeux, notamment en ce qui concerne la conception et la R&D.</p>
                <p className="text-cartouche"> La CFD (Computational Fluid Dynamics), également appelée Mécanique des Fluides assistée par ordinateur, est un outil informatique de modélisation des mouvements de fluides (liquide, gaz), des propriétés de ces écoulements (vitesse, pression, température ...) et des interactions avec leur environnement (échange de chaleur, réactions chimiques, forces aérodynamiques, aéroacoustique).</p>
            </div>
            <div className="objectifs-pictures">
                <div className="objectifs-pictures-card" data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-duration="900">
                    <img src={img_graph1} alt="Graphique analyse de mécanique des fluides" />
                    <img src={img_graph2} alt="Graphique analyse de mécanique des fluides" />
                </div>
            </div>
        </main>
    )
}