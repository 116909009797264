import React, { useContext } from "react";
import {NavLink} from 'react-router-dom'
import MenuContext from "./../MenuContext";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// Images

import HomeHeader from "../../images/carousel/Fond-HomeHeader.jpg"
import Carousel2 from "../../images/carousel/boat.png"
import Carousel3 from "../../images/carousel/avion_decollage.png"
import Carousel4 from "../../images/carousel/f1_face.png"
import Carousel5 from "../../images/carousel/voiture_profil.png"
import BaselineFLYNAERO from "../../images/logos/Baseline_FLYNAERO.png"
import img_BureauEtudes from "../../images/flynaero/Bureau_Etudes.jpg"


export default function Home() {

    const { SetEtudesScreen } = useContext(MenuContext)

    return(
        <header>
            <div className="carousel">
                <Carousel showThumbs={false} showArrows={false} infiniteLoop={true} autoPlay={true} interval="4000" transitionTime="800" swipeScrollTolerance="10" showStatus={false}>
                    <div>
                        <img  src={HomeHeader} alt="Airbus A350" />
                    </div>
                    <div>
                        <img src={Carousel2} alt="Aérodinamisme" />
                    </div>
                    <div>
                        <img src={Carousel3} alt="Decollage Airbus" />
                    </div>
                    <div>
                        <img src={Carousel4} alt="Formule 1" />
                    </div>
                    <div>
                        <img src={Carousel5} alt="Voiture de sport" />
                    </div>
                </Carousel>
            </div>
            <div className="baseline">
                <img  src={BaselineFLYNAERO} alt="Baseline" />
            </div>
            <div className="header-down">
                <div className="card-revolution">
                    <h2 className="titre-cartouche" >La révolution en action</h2>
                    <p className="text-cartouche">
                        La méthode DPSM par son mode de calcul direct non itératif de grandeur physique aboutit à des performances, en temps de calcul, plusieurs milliers de fois plus grandes que la méthode utilisée à ce jour. 
                    </p>
                    <h2 className="titre-cartouche">Notre logiciel révolutionnaire</h2>
                    <NavLink  style={{ textDecoration: 'none' }} exact to="/BureauEtudes/Easy-Fluid" activeClassName="active">
                        <button className="ingenieries">
                            <span className="circle">
                                <span className="icon arrow"></span>
                            </span>
                            <span className="button-text">Easy-Fluid</span>
                        </button>
                    </NavLink>
                </div>
                <div className="container-button" data-aos="flip-right" data-aos-delay="500" data-aos-easing="ease-in-out" data-aos-duration="1000">
                    <div className="container-image">
                        <img className="image-ingenieries" src={img_BureauEtudes} alt="Bureau d'études" />
                        <div className="overlay"></div>
                    </div>
                    <NavLink exact to="/BureauEtudes" activeClassName="active" onClick={ SetEtudesScreen }>
                        <button className="ingenieries">
                            <span className="circle">
                                <span className="icon arrow"></span>
                            </span>
                            <span className="button-text">Bureau d'études</span>
                        </button>
                    </NavLink>
                </div>
            </div>
        </header>
    )
}